
















































































import Vue from 'vue';
import PollList from '@/components/Presenter/PollList.vue';
import { PresenterPoll } from '@/api/interfaces.api';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: {
    PollList,
  },
  data() {
    return {
      drag: false,
      sortedPollls: [] as PresenterPoll[],
    };
  },
  async mounted() {
    const presenterSessionId = this.$route.params.presenterSessionId;
    await this.$store.dispatch(
      'presenter/fetchPollResults',
      presenterSessionId
    );
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
      polls: 'presenter/getSessionPolls',
      loading: 'presenter/getLoadingState',
    }),
    computedTitleValue(): string {
      return (
        (this.currentSession.labels && this.currentSession.labels['polls']) ||
        this.$t('components.presenter.polls')
      );
    },
  },
  methods: {
    fetchData() {
      const presenterSessionId = this.$route.params.presenterSessionId;
      this.$store.dispatch('presenter/fetchPollResults', presenterSessionId);
    },
  },
});
