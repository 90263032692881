















































































































































































































import Vue from 'vue';
import SliderPollAnswers from './SliderPollAnswers.vue';
import PollResults from '@/components/Presenter/PollResults.vue';
import TVButton from '@/components/Presenter/TVButton.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: { SliderPollAnswers, PollResults, TVButton },
  props: {
    polls: {
      type: Array,
      required: true,
    },
    currentTVMode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getChoicesAnswers: 'presenter/getChoicesAnswers',
    }),
  },
  methods: {
    resetPoll(pollId: string) {
      if (
        confirm(this.$t('components.presenter.pollListResetConfirm') as string)
      ) {
        this.$store.dispatch('presenter/resetPoll', {
          pollId,
        });
      }
    },
    togglePollPaused(pollId: string, state: boolean) {
      this.$store.dispatch('presenter/togglePollPaused', {
        pollId,
        state,
      });
    },
    removePoll(pollId: string) {
      if (
        confirm(this.$t('components.presenter.pollListDeleteConfirm') as string)
      ) {
        this.$store.dispatch('presenter/removePoll', {
          pollId,
        });
      }
    },
    editPoll(pollId: string) {
      this.$router.push({
        name: 'Presenter Session Edit Poll',
        params: { pollId },
      });
    },
    togglePollVisibility(pollId: string, state: boolean) {
      this.$store.dispatch('presenter/togglePollVisibility', {
        pollId,
        state,
      });
    },
    togglePollAnimation(pollId: string) {
      this.$store.dispatch('presenter/togglePollAnimation', {
        pollId,
      });
    },
    togglePollStatisticsVisibility(pollId: string, state: boolean) {
      this.$store.dispatch('presenter/togglePollStatisticsVisibility', {
        pollId,
        state,
      });
    },
    resetPollAnimation(pollId: string) {
      this.$store.dispatch('presenter/resetPollAnimation', {
        pollId,
      });
    },
    openResultLink(pollId: string) {
      window.open(
        `https://europe-west3-reaction-link-dev-160b1.cloudfunctions.net/evaluateCommentsByPollID?sessionID=HBoph8OE&pollID=${pollId}`
      );
    },
    getPollAnswer(pollId: string): any {
      if (pollId in this.getChoicesAnswers) {
        return this.getChoicesAnswers[pollId];
      }
      return {};
    },
    syncPollResults(pollId: string) {
      const pollResults = this.getPollAnswer(pollId).choices;
      this.$store.dispatch('presenter/syncPollResults', {
        pollResults,
      });
    },
  },
});
