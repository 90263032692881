













































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'SliderPollAnswers',
  props: {
    poll: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getSliderAnswers: 'presenter/getSliderAnswers',
    }),
    pollAnswer(): any {
      if (this.poll.id in this.getSliderAnswers) {
        return this.getSliderAnswers[this.poll.id];
      }
      return {};
    },
  },
  methods: {},
});
